.mainContainer {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.col {
  height: 95%;
  display: inline-block;
  width: min-content;
}

.rightCol {
  width: 100px;
}

.topRow {
  height: 75%;
  width: inherit;
  display: flex;
  align-items: flex-end;
}

.bottomRow {
  height: 25%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.updatedAt {
  max-width: 89%;

  @media screen and (min-width: 1440px) {max-width: 60%;}
}

.currentHostCount {
  font-size: 57px;
  font-weight: bold;

  @media screen and (min-width: 620px) {font-size: 65px;}
  @media screen and (min-width: 1440px) {font-size: 80px;}
  @media screen and (min-width: 1680px) {font-size: 96px;}
}

.maxHostCount {
  font-size: 34px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #298340;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 1440px) {
    font-size: 40px;
    margin-bottom: 11px;
  }
}
