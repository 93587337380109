.countContainer {
  flex-direction: column;
  display: flex;
  align-items: center;
  flex-basis: 100%;
}

.widgetTitle {
  text-align: center;
  font-size: 1.2em;
  margin-top: auto;
  color: grey;
}
