.widgetTitle {
  text-align: center;
  font-size: 1.2em;
  margin-top: auto;
  color: grey;
}

.widgetContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  justify-content: space-around;
}

.rowGroup {
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  span {
    width: 35%;
    position: relative;
    text-align: center;
    z-index: 2;
    color: #fff;
    &:before {background-color:#6b6b6b;}

    &.ok, &.enabled {
      color: #fff;
      &:before {background-color:#24843d;}
    }
    &.different_version {
      color: #000;
      &:before {background-color:#d5fa51;}
    }
    &.disabled {
      color: #000;
      &:before {background-color:#ffcc00;}
    }
    &.error {
      color: #fff;
      &:before {background-color:#FF0000;}
    }
    &:before {position:absolute; top: -5px; left:0; width:100%; height:30px; content:''; border-radius:5px; z-index:-1;}
  }
}
