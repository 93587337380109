.gridContainer {
  display: flex;
  align-items: center;
  font-size: 2em;
}

.col {
  margin-right: 15px;
  font-weight: 700;
}

.colSmall {
  flex: 1.2;
  min-width: 75px;
  margin-right: 0;
  text-align: center;
}

.colMed {
  flex: 3.1;
  min-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.colLarge {
  flex: 4;
  min-width: 300px;
  margin-right: 15px;
}

.graphContainer {
    flex: 1;
    overflow: hidden;
}
