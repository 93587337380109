.loginBtn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  background: #c6463e;
  border-color: #c6463e;
  color: #fff;
  width: 100%;
  height: 40px;
  &:hover, &:focus, &:active, &:active:focus {
    box-shadow: none;
    color: #fff;
    background: #d63934;
    border-color: #d63934;
  }
}

.errorAlert {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}
