.flexColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
  &.columnNormal { flex: 4; }
  &.columnTiny { flex: 1; min-width: 0px; margin-left: -18px;}
}

.flexRow {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  &.rowNormal { flex: 4; }
  &.rowTiny { flex: 1 }
}

.flexGrid {
  display: flex;
  flex-direction: row;
  height: 90%;
}

.label {
  color: grey;
  &.rotated {
    transform: rotate(-90deg);
    margin-top: 10px;
  }
}

.accountValues {
  font-weight: bold;

  font-size: 3.5em;
  @media screen and (min-width: 600px) { font-size: 4em; }
  @media screen and (min-width: 950px) { font-size: 4.5em; }
}
