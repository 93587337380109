.card {
  background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
    height: inherit;
    padding: 1em;
}

.cardTitle {
  text-align: center;
  margin: 0.25em;
}
