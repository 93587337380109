.loginPageContainer {
  background: #dcdbdb;
  background-size: cover;
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loginFormContainer {
  margin: auto;
  flex-basis: 400px;
  @media screen and (min-width: 1000px) { flex-basis: 475px;}
}

.addressFinderLogo {
  background: url('../../images/logo_addressfinder.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 75px;
  margin: 2rem 0;
}

.form {
  padding: 1em 2em;
}
