// Containers
.widgetContainer {
  height: 100%;
  display: contents;
  justify-content: center;
}

.countryTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  color: '#000000';
}
