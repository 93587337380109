.col {
  font-weight: 700;
}

.col:not(:last-child) {
  margin-right: 15px;
}

.colSmall {
  flex: 1;
  min-width: 75px;
}

.colMed {
  flex: 2 1;
  min-width: 215px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.colLarge {
  flex: 3;
  min-width: 300px;
}

.graphContainer {
  flex: 1;
  overflow: hidden;
}

.gridContainer {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 3.3em;
  @media screen and (min-width: 1500px) { font-size: 4em; }
}

.graphContainer {
    flex: 1;
    overflow: hidden;
}
